import React from 'react';
import './header.css';

function Header(props) {
    return (
        <div className="header">
            <div className="header-logo"/>
            <div className="header-width-one-hundred" />
            {props.children}
        </div>
    );
}

export default Header;

import './index.css';
import firebase from 'firebase';
import {firebaseConfig} from './fireBase/firebaseConfig.js';
import * as ReactDOM from "react-dom";
import React from 'react';
import functionHandler from './functionHandler';
import Login from "./login/login";
import App from "./App";
import NoLink from "./noLink/noLink";

firebase.initializeApp(firebaseConfig);
const pathName = document.location.pathname.toLowerCase();

if (document.location.pathname === '/') {
    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            ReactDOM.render(<App user={user}/>, document.getElementById('root'));
        } else {
            ReactDOM.render(<Login/>, document.getElementById('root'));
        }
    });
} else {
    functionHandler.sendData('/getLink', pathName, {}, (payload) => {
        if (payload) {
            document.location = payload;
        } else {
            ReactDOM.render(<NoLink/>, document.getElementById('root'));
        }
    });
}

import React, {Component} from 'react';
import './login.css';
import firebase from 'firebase';

import TabPage from '../tabPage/tabPage';
import Header from '../header/header';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {email: '', password: '', name: '', gdpr: false, errorMessage: ''};
    }

    onEmailChange = event => {
        this.setState({
            email: event.target.value,
            errorMessage: !event.target.value ? 'Please provide an email address' : ''
        });
    };

    onPasswordChange = event => {
        this.setState({
            password: event.target.value,
            errorMessage: !event.target.value ? 'Please provide a password' : ''
        });
    };

    gdprCheckboxChange = event => {
        this.setState({gdpr: event.target.checked});
    };

    signIn = event => {
        if (this.state.password && this.state.email) {
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(error => {
                this.setState({errorMessage: error.message});
            });
        } else {
            this.setState({errorMessage: 'All data is mandatory!'});
        }
    };

    signUp = event => {
        if (this.state.password && this.state.email) {
            firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).catch(error => {
                this.setState({errorMessage: error.message});
            });
        } else {
            this.setState({errorMessage: 'All data is mandatory!'});
        }
    };

    onKeyDown = (event, callback) => {
        if (event.key === 'Enter') {
            callback();
        }
    };

    signInJsx = () => {
        return <div className="login">
            {this.state.errorMessage ? <div className="error-message">{this.state.errorMessage}</div> : ''}
            <div className="email-label">E-mail address</div>
            <input type="email" onChange={this.onEmailChange} onKeyDown={event => this.onKeyDown(event, this.signIn)}/>
            <div className="password-label">Password</div>
            <input type="password" onChange={this.onPasswordChange}
                   onKeyDown={event => this.onKeyDown(event, this.signIn)}/>
            <button className="myButton" onClick={this.signIn}>Sign in</button>
        </div>
    };

    signUpJsx = () => {
        return <div className="login">
            {this.state.errorMessage ? <div className="error-message">{this.state.errorMessage}</div> : ''}
            <div className="email-label">E-mail address</div>
            <input type="email" onChange={this.onEmailChange} onKeyDown={event => this.onKeyDown(event, this.signUp)}/>
            <div className="password-label">Password</div>
            <input type="password" onChange={this.onPasswordChange}
                   onKeyDown={event => this.onKeyDown(event, this.signUp)}/>
            <div className="gdpr-checkbox">
                <input type="checkbox" id="gdpr" value={this.state.gdpr} onChange={this.gdprCheckboxChange}/>
                <label htmlFor="gdpr">I understand and accept that all my information will be saved on server</label>
            </div>
            <button className="myButton" disabled={!this.state.gdpr} onClick={this.signUp}>Sign up</button>
        </div>
    };

    onTabClick = () => {
        this.setState({errorMessage: ''});
    };

    render() {
        return (<React.Fragment>
                <div className="login-container">
                    <div className="login-content">
                        <TabPage tabs={{'Sign in': this.signInJsx()}}
                                 onClick={this.onTabClick}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Login;

import React from 'react';
import './link.css';
import {database} from 'firebase';
import Usage from "../usage/usage";
import functionHandler from "../../functionHandler";
import QrCodeContainer from "../qrCodeContainer/qrCodeContainer";

class Link extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editMode: false};
    }

    editClick = () => {
        this.setState({editMode: !this.state.editMode, url: ''})
    };

    copyLink = () => {
        let copyText = document.createElement('input', {type: 'text', id: 'myInput'});
        document.body.appendChild(copyText);
        copyText.value = this.getFullLink();
        copyText.select();
        document.execCommand("copy");
        copyText.remove();
    };

    getFullLink() {
        return document.location.origin + '/' + this.props.path;
    }

    deleteClick = () => {
        database().ref('/urls/' + this.props.userId + '/' + this.props.path + '/flaggedForDeletion').set(true, () => {
            functionHandler.sendData('/deleteLink', '/' + this.props.path, {userId: this.props.userId});
        });
    };

    save = () => {
        console.log(this.state.url);
        if (this.state.url) {
            console.log(this.state.url);
            database().ref('/urls/' + this.props.userId + '/' + this.props.path + '/url').set(this.state.url, () => {
                this.setState({url: '', editMode: false});
            });
        } else {
            this.setState({url: '', editMode: false});
        }
    };

    onUrlChange = event => {
        this.setState({url: event.target.value})
    };

    onUrlKeyDown = event => {
        if (event.keyCode === 13) {
            this.save();
        }
    };

    isShown() {
        return !this.props.searchValue || (this.props.path + this.props.url).toLowerCase().indexOf(this.props.searchValue.toLowerCase()) > -1;
    }

    render() {
        return this.isShown() ? <div className="link-outer-container">
            <div className="link-container">
                <div className="link-header">
                    <div className="link-path" title={this.props.path}><a
                        href={'/' + this.props.path} target="_blank">/{this.props.path}</a></div>
                    <div className="link-copy copy-icon" onClick={this.copyLink}/>
                    <div className="space-between"/>
                    {this.props.isCurrentUser ? <div className="link-edit edit-icon" onClick={this.editClick}/> : ''}
                    {this.props.isCurrentUser ? <div className="link-delete delete-icon" onClick={this.deleteClick}/> : ''}
                </div>

                {this.state.editMode ?
                    <div>
                        <input className="link-url-input" value={this.state.url || this.props.url}
                               onChange={this.onUrlChange} onKeyDown={this.onUrlKeyDown}/>
                        <div className="link-edit-buttons">
                            <div className="link-save" onClick={this.save}>✓</div>
                            <div className="link-cancel" onClick={this.editClick}>X</div>
                        </div>
                    </div> :
                    <div className="link-url" title={this.props.url}>
                        <a href={this.props.url} target="_blank">{this.props.url}</a>
                    </div>}
                <Usage usage={this.props.usage}/>
                <QrCodeContainer fullLink={this.getFullLink()} path={this.props.path}/>
            </div>
        </div> : '';
    }
}

export default Link;

import React from 'react';
import './noLink.css';
import '../App.css';

class NoLink extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="app">
            <div className="no-link">
                <h1>Sorry!</h1>
                <h4>We did our best, but this link<br/>is not pointing to anywhere.</h4>
            </div>
            <div className="stick-figure-icon background-icon"/>
        </div>
    }
}

export default NoLink;

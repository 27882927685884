import React from 'react';
import './usage.css';
import '../link/link.css';
import Chart from "react-apexcharts";
import md5 from 'md5';

class Usage extends React.Component {
    constructor(props) {
        super(props);
        let allCount = 0;
        Object.keys(this.props.usage).forEach(date => {
            allCount += this.props.usage[date];
        });

        const categories = Usage.getCategories(7, 0);
        this.state = {
            showUsage: false,
            allCount: allCount,
            days: 7,
            differentFromToday: 0,
            chartKey: md5(JSON.stringify(this.props.usage)),
            chartOptions: {
                chart: {
                    type: 'area',
                    stacked: false,
                    height: 350,
                    zoom: {
                        type: 'x',
                        enabled: false,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        autoSelected: 'zoom'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                },
                xaxis: {
                    categories: categories
                }
            },
            series: [{
                name: 'count',
                data: categories.map(date => (this.props.usage[date] || 0))
            }]
        };
    }

    static getDerivedStateFromProps(props, state) {
        let chartKey = md5(JSON.stringify(props.usage));
        if (state.chartKey !== chartKey) {
            state.chartKey = chartKey;
            let allCount = 0;
            Object.keys(props.usage).forEach(date => {
                allCount += props.usage[date];
            });
            state.allCount = allCount;
            Usage.staticRefreshChart(state.days || 7, state.differentFromToday || 0, state, props);

            return state;
        }

        return null;
    }

    static getCategories(days, differentFromToday) {
        let daysArray = [];
        for (let i = 0; i < days; i++) {
            let day = new Date(Date.now() + (1000 * 60 * 60 * 24 * differentFromToday) - (1000 * 60 * 60 * 24 * i));
            let formatedDate = day.getUTCFullYear() + '-' + ('00' + (day.getUTCMonth() + 1)).slice(-2) + '-' + ('00' + day.getUTCDate()).slice(-2);
            daysArray.push(formatedDate);
        }

        return daysArray.sort();
    }

    dateChange = event => {
        let days = event.target.value;
        this.refreshChart(days, this.state.differentFromToday);
        this.setState({days});
    };

    static staticRefreshChart(days, differentFromToday, state, props) {
        const categories = Usage.getCategories(days, differentFromToday);
        state.chartOptions.xaxis.categories = categories;
        state.series = [{
            name: 'count',
            data: categories.map(date => (props.usage[date] || 0))
        }];
    }

    refreshChart = (days, differentFromToday) => {
        Usage.staticRefreshChart(days, differentFromToday, this.state, this.props);
    };

    goLeft = () => {
        let differentFromToday = this.state.differentFromToday - 1;
        this.refreshChart(this.state.days, differentFromToday);
        this.setState({differentFromToday});
    };

    goRight = () => {
        let differentFromToday = this.state.differentFromToday + 1;
        this.refreshChart(this.state.days, differentFromToday);
        this.setState({differentFromToday});
    };

    toggleUsageContainer = () => {
        this.setState({showUsage: !this.state.showUsage});
    };

    render() {
        return <div className="usage">
            <div className="usage-panel-header" onClick={this.toggleUsageContainer}>
                <div
                    className={'usage-panel-open-icon ' + (this.state.showUsage ? 'open-label-icon' : 'closed-label-icon')}/>
                <div className="usage-panel-open-label">Show usage</div>
            </div>
            {this.state.showUsage ? <React.Fragment>
                <div>All click: {this.state.allCount}</div>
                <Chart key={this.state.chartKey + '-' + this.state.days + '-' + this.state.differentFromToday}
                       options={this.state.chartOptions} series={this.state.series}
                       height={200}/>
                <div className="usage-days">
                    <div className="usage-days-text">Show</div>
                    <input className="usage-day-input" type="number" value={this.state.days}
                           onChange={this.dateChange}/>
                    <div className="usage-days-text">days</div>
                    <div className="space-between"/>
                    <button className="usage-button" onClick={this.goLeft}>&lt;</button>
                    <div className="space-between-buttons"/>
                    <button className="usage-button" onClick={this.goRight}>&gt;</button>
                </div>
            </React.Fragment> : ''}
        </div>;
    }
}

export default Usage;

import React from 'react';
import './links.css';
import {database} from 'firebase';
import Link from "./link/link";
import AddLink from "./addLink/addLink";

class Links extends React.Component {
    constructor(props) {
        super(props);
        this.state = {urls: {}, usage: {}, addLink: false, searchValue: ''};
    }

    componentDidMount() {
        database().ref('/usageData/' + this.props.userId).on('value', usageData => {
            let usage = (usageData.val() || {}).urls || {};
            this.setState({usage});
        });
        database().ref('/urls/' + this.props.userId).on('value', urlsData => {
            let urls = urlsData.val();
            this.setState({urls});
        });
    }

    toggleAdd = () => {
        this.setState({addLink: !this.state.addLink});
    };

    onSearchChange = event => {
        this.setState({searchValue: event.target.value});
    };

    render() {
        return <div className="links-container">
            <div className="search-line">
                <input className="search-input search-icon" type="text" value={this.state.searchValue} onChange={this.onSearchChange}/>
            </div>
            <div className="links-container-header">
                <div className="links-label">Links:</div>
                {this.props.isCurrentUser ? <div className="links-add" onClick={this.toggleAdd}>+</div> : ''}
            </div>
            {this.state.addLink ? <AddLink userId={this.props.userId} cancel={this.toggleAdd}/> : ''}
            {Object.keys(this.state.urls || {}).map(path =>
                <Link key={path} url={this.state.urls[path].url} usage={(this.state.usage[path] || {}).count || {}}
                      path={path} userId={this.props.userId} searchValue={this.state.searchValue}
                      isCurrentUser={this.props.isCurrentUser}/>)}
        </div>
    }
}

export default Links;

import React from 'react';
import {auth, database} from 'firebase';
import './App.css';
import Header from "./header/header";
import Links from "./links/links";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isAdmin: false, users: {}, selectedId: this.props.user.uid};
        console.log(this.props.user.uid);
    }

    componentDidMount() {
        database().ref('/admins/' + this.props.user.uid).on('value', isAdminData => {
            let isAdmin = isAdminData.val();
            database().ref('/users').on('value', usersData => {
                this.setState({isAdmin: isAdmin, users: (usersData.val() || {})});
            });
        });
    }

    logOut = () => {
        auth().signOut();
    };

    selectUser = event => {
        this.setState({selectedId: event.target.value});
    };

    render() {
        return <div className="app">
            <Header/>
            <div className="logout-icon" onClick={this.logOut}/>
            {this.state.isAdmin ? <select className="admin-user-selector" value={this.state.selectedId} onChange={this.selectUser}>
                {Object.keys(this.state.users).map(userId =>
                    <option key={userId} value={userId}>{this.state.users[userId].email || userId}</option>
                )}
            </select> : ''}
            <div className="content">
                <Links key={this.state.selectedId} userId={this.state.selectedId} isCurrentUser={this.props.user.uid === this.state.selectedId}/>
            </div>
        </div>
    }
}

export default App;

var FunctionHandler = function FunctionHandler() {
    this.sendData = function sendData(url, id, payload, callback) {
        var backendLink = 'https://us-central1-justgr.cloudfunctions.net/redirect';
        fetch(backendLink + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id, payload})
        }).then((response) => response.json().then(data => {
            if (callback) {
                callback(data.payload, data.code !== 200);
            }
        })).catch(() => {
            if (callback) {
                callback(null, true);
            }
        });
    }
};

export default new FunctionHandler();

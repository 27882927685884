import React from 'react';
import './qrCodeContainer.css';
import QRCode from "react-qr-code";

class QrCodeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showQrCode: false, imageType: 'png'};
    }

    toggleQRContainer = () => {
        this.setState({showQrCode: !this.state.showQrCode});
    };

    downloadQrCode = event => {
        event.stopPropagation();
        this.download(this.props.path);
    };

    typeSelectorChange = event => {
        this.setState({imageType: event.target.value});
    };

    download = (filename) => {
        const input = document.querySelector('#qr-code' + this.props.path + ' > svg');
        const image = new Image();

        const svgData = new XMLSerializer().serializeToString(input);
        const svgDataBase64 = btoa(unescape(encodeURIComponent(svgData)));
        const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

        if (this.state.imageType === 'png') {
            image.addEventListener('load', () => {
                const width = input.getAttribute('width');
                const height = input.getAttribute('height');
                const canvas = document.createElement('canvas');

                canvas.setAttribute('width', width);
                canvas.setAttribute('height', height);

                const context = canvas.getContext('2d');
                context.drawImage(image, 0, 0, width, height);

                const dataUrl = canvas.toDataURL('image/png');
                this.pushToDownload(dataUrl, filename + '.png');
            });

            image.src = svgDataUrl;
        } else {
            this.pushToDownload(svgDataUrl, filename + '.svg');
        }
    };

    pushToDownload = (dataUrl, filename) => {
        var element = document.createElement('a');
        element.setAttribute('href', dataUrl);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    render() {
        return <div className="qr-code-panel-container">
            <div className="qr-code-panel-header" onClick={this.toggleQRContainer}>
                <div
                    className={'qr-code-panel-open-icon ' + (this.state.showQrCode ? 'open-label-icon' : 'closed-label-icon')}/>
                <div className="qr-code-panel-open-label">Show QR Code</div>
                <div className="space-between"/>
                {this.state.showQrCode ?
                    <React.Fragment>
                        <select value={this.state.imageType} onChange={this.typeSelectorChange} onClick={event => event.stopPropagation()}>
                            <option value="png">PNG</option>
                            <option value="svg">SVG</option>
                        </select>
                        <div className="space-20px"/>
                        <button onClick={this.downloadQrCode}>Download</button>
                    </React.Fragment> : ''}
            </div>
            {this.state.showQrCode ?
                <div className="qr-code-container" id={'qr-code' + this.props.path}>
                    <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "300px", width: "80%"}}
                        value={this.props.fullLink}
                        viewBox={`0 0 256 256`}
                        title={this.props.path}
                    />
                </div> : ''}
        </div>
    }
}

export default QrCodeContainer;

import React, {Component} from 'react';
import './tabPage.css';

class TabPage extends Component {
    constructor(props) {
        super(props);
        this.state = {selected: Object.keys(this.props.tabs)[0]};
    }

    onClick = (selected) => {
        this.setState({selected: selected});
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(selected);
        }
    };

    render() {
        return (
            <div className="tab-page">
                <div className="tab-header">
                    {Object.keys(this.props.tabs).map(key =>
                        <div key={key} className={this.state.selected === key ? 'tab-selector selected' : 'tab-selector'}
                            onClick={() => this.onClick(key)}>{this.props.labels && this.props.labels[key] ? this.props.labels[key] : key}</div>
                    )}
                </div>
                <div className="tab-container">
                    {this.props.tabs[this.state.selected] ? this.props.tabs[this.state.selected] : ''}
                </div>
            </div>
        );
    }
}

export default TabPage;

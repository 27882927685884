import React from 'react';
import './addLink.css';
import '../link/link.css';
import functionHandler from '../../functionHandler';
import {database} from "firebase";


class AddLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {url: '', path: '/', existsError: false};
    }

    onPathChange = event => {
        let value = event.target.value;
        if (value.indexOf('/') !== 0) {
            value = '/' + value;
        }

        this.setState({path: value.toLowerCase(), existsError: false});
    };

    onUrlChange = event => {
        this.setState({url: event.target.value});
    };

    save = () => {
        database().ref('/urlUserMappings' + this.state.path).once('value', data => {
            let exists = data.val();
            if (exists) {
                this.setState({existsError: true});
            } else {
                let id = this.state.path;
                if (this.state.path.indexOf('/') !== 0) {
                    id = '/' + id;
                }

                functionHandler.sendData('/createLink', id, {
                    userId: this.props.userId,
                    url: this.state.url
                }, () => {
                    this.props.cancel();
                });
            }
        });
    };

    render() {
        return <div className="link-outer-container">
            <div className="link-container">
                <label className="add-link-label">Path:</label>
                <input className="link-url-input" value={this.state.path} onChange={this.onPathChange}/>
                {this.state.existsError ? <div className="error-message">The path is already taken!</div> : ''}
                <label className="add-link-label">Url:</label>
                <input className="link-url-input" value={this.state.url} onChange={this.onUrlChange}/>
                <div className="link-edit-buttons">
                    <div className="link-save" onClick={this.save}>✓</div>
                    <div className="link-cancel" onClick={this.props.cancel}>X</div>
                </div>
            </div>
        </div>
    }
}

export default AddLink;
